<template>
  <section>
    <!-- form-geral -->

    <form @submit.prevent="getProdutos(1)">
      <div class="card card_border">
        <div class="cards__heading">
          <h3>Pesquisa <span>...</span></h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              
              <label for="inputID4" class="input__label"
                >ID</label
              >
              <input
                type="text"
                class="form-control input-style"
                name="id"
                v-model="paramsSearch.id"
                placeholder="ID"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              
              <label for="inputDescricao4" class="input__label"
                >Descricao</label
              >
              <input
                type="text"
                class="form-control input-style"
                name="descricao"
                v-model="paramsSearch.descricao"
                placeholder="Descricao"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              
              <label for="inputReferencia4" class="input__label"
                >Referencia/Localizador</label
              >
              <input
                type="text"
                class="form-control input-style"
                name="referencia"
                v-model="paramsSearch.referencia"
                placeholder="Referencia"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              
              <label for="inputReferencia4" class="input__label"
                >Veiculo</label
              >
              <input
                type="text"
                class="form-control input-style"
                name="veiculo"
                v-model="paramsSearch.veiculo"
                placeholder="veiculo"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              
              <label for="inputReferencia4" class="input__label"
                >Codigo Genuíno</label
              >
              <input
                type="text"
                class="form-control input-style"
                name="codigo_genuino"
                v-model="paramsSearch.codigo_genuino"
                placeholder="Codigo Genuíno"
              />
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-primary btn-style">
            Pesquisar
          </button>
        </div>
      </div>
    </form>
    <!-- /form-geral -->

    <!-- data tables -->
    <Table :fields="fields" :rowsData="produtos" :meta="meta" :getData="getProdutos" :optionsEdit="deleteProduto" />
    <!-- //data tables -->
  </section>
</template>

<script>

import { onMounted } from "vue";

import Table from "@/components/Table.vue";
import useCommon from "@/common";
import useProduto from '@/composables/Produto';

export default {
  components: {
    Table,
  },
  setup() {
    const { openToast } = useCommon();
    const { status, message, error, meta, paramsSearch, produtos, getProdutos, destroyProduto } = useProduto();

    const showMessages = () => {
      if(status.value){
        openToast(message.value, 'success');
      }else{
        openToast(error.value, 'error');
      }
    };

    const deleteProduto = async (id) => {
      if (!window.confirm("Confirm??")) {
        return;
      }

      await destroyProduto(id);      
      showMessages();
      await getProdutos();
    };

    onMounted(function () {
      getProdutos();
    });
    
    const fields = ["id", "veiculo", "lado", "ano_inicial", "ano_final", "referencia", "descricao", "obs", "preco", "codigo_genuino"];

    return {
      paramsSearch,
      meta,
      produtos,
      deleteProduto,
      getProdutos,
      fields
    };
  },
};
</script>
