<template>
  <div v-if="rowsData.length > 0">
    <h3>Lista</h3>
    <div class="table-responsive">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th width=168>...</th>
            <th v-for="field in fields" :key="field" >
              {{ field }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Loop through the list get the each student data -->
          <tr v-for="row in rowsData" :key="row">
              <td width="200">
                <button class="btn btn-primary btn-xs"
                  @click="()=>$router.push({ name: `${$router.currentRoute.value.meta.base}.edit`, params: { id: row.id },})">
                  editar
                </button>
                - 
                <button class="btn btn-danger btn-xs"
                  @click="optionsEdit(row.id)">
                  excluir
                </button>
                <template v-if="$router.currentRoute.value.meta.base == 'app.produto'">
                  - 
                  <template v-if="row.showEdit==undefined || !row.showEdit">
                    <button class="btn btn-secondary btn-xs"
                      @click="optionsEditX(row)">
                      editar
                    </button>
                  </template>
                  <template v-if="row.showEdit">
                    <button class="btn btn-warning btn-xs"
                      @click="optionsEditXX(row)">
                      salvar
                    </button>
                  </template>
                </template>
                <slot name="opt_column_extra" v-bind:row="row"></slot>
              </td>

            <td v-for="field in fields" :key="field">
              <template v-if="row.showEdit 
                  && produtoEdit_[row.id]!= undefined 
                  && ['veiculo', 'lado', 'ano_inicial', 'ano_final', 'referencia', 'descricao', 'preco', 'obs', 'codigo_genuino'].includes(field)">
                <input
                  type="text"
                  class="form-control input-style btn-xs"
                  :placeholder="field"
                  name="descricao" v-model="produtoEdit_[row.id][field]"
                />
              </template>
              <template v-else>
                <template v-if="produtoEdit_[row.id]!=undefined">
                  {{ produtoEdit_[row.id][field] }}
                </template>
                <template v-else>
                  {{ row[field] }}
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <div class="row  text-center" v-if="meta">
        <div class="col-12">
          <ul class="pagination justify-content-center">
            <template v-for="n in meta.last_page" :key="n">
              <li
                :class="{ 'page-item': true, active: n == meta.current_page }"
              >
                <a
                  class="page-link"
                  @click="getData(n)"
                  href="javascript:void(0);"
                  >{{ n }}</a
                >
              </li>
            </template>
          </ul>
          <p class="text-center">
            Exibindo {{ meta.from }} ate {{ meta.to }} de
            {{ meta.total }} registros
          </p>
        </div>
      </div>
      <div style="display: none" class="row">
        <div class="col-4">
          <p>
            Exibindo {{ meta.from }} ate {{ meta.to }} de
            {{ meta.total }} registros
          </p>
        </div>
        <div class="col-8">
          <ul class="pagination justify-content-end">
            <template v-for="n in meta.last_page" :key="n">
              <li
                :class="{ 'page-item': true, active: n == meta.current_page }"
              >
                <a
                  class="page-link"
                  @click="getData(n)"
                  href="javascript:void(0);"
                  >{{ n }}</a
                >
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { reactive } from "vue"
import useCommon from "@/common";
import useProduto from '@/composables/Produto';

export default {
  name: "TableComponent",
  props: {
    //
    rowsData: {
      type: Array,
    },
    fields: {
      type: Array,
    },
    meta: {
      type: Object
    },
    getData: {
      type: Function
    },
    optionsEdit: {
      type: Function
    }
  },
  setup() {

    const { openToast } = useCommon();
    const produtoEdit_ = reactive({});
    const { produto: produtoEdit, getProduto, patchProduto, status, message, error } = useProduto();

    const showMessages = () => {
      if(status.value){
        openToast(message.value, 'success');
      }else{
        openToast(error.value, 'error');
      }
    };

    const optionsEditX = async (data_) => {
      if(data_.showEdit == undefined){
        data_.showEdit = true;
      }else{
        data_.showEdit = (data_.showEdit)?false:true;
      }

      if(data_.showEdit){
        await getProduto(data_.id);
        produtoEdit_[data_.id] = produtoEdit.value;
      }
    }

    const optionsEditXX = async (data_) => {
      await patchProduto(data_.id, produtoEdit_[data_.id]);
      showMessages();
      if(status){
        await getProduto(data_.id);
        produtoEdit_[data_.id] = produtoEdit.value;
        data_.showEdit = false;
      }
    } 

    return {
      optionsEditX,
      produtoEdit,
      produtoEdit_,
      optionsEditXX

    };
  }
};
</script>
