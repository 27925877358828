<template>
  <div>
    <input type="file" accept="image/jpeg" @change="uploadImage" />

    <div class="progress">
      <div
        class="progress-bar progress-bar-info"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: progress + '%' }"
      >
        {{ progress }}%
      </div>
    </div>

    <div v-if="previewImage">
      <div>
        <img class="preview my-3" :src="previewImage" alt="" />
      </div>
    </div>
    <div v-else-if="!previewImage && modelValue">
      <div>
        <img class="preview my-3" :src="`${publicUrl+modelValue}`" :alt="modelValue" />
      </div>
    </div>

    <button v-if="modelValue" class="btn btn-danger btn-xs" @click="removeImage">Remover</button>

    <div v-if="message" class="alert alert-secondary" role="alert">
      {{ message }}
    </div>
  </div>
</template>

<script>

import { ref } from "vue";
import useCommon from "@/common";

export default {
  name: "ImageUpload",
  components: {},
  props: {
    modelValue: [String, Number]
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    let previewImage = ref("");
    let progress = ref(0);
    let message = "";

    const { upload, openToast } = useCommon();

    const sendEmit = (data) => {
      emit("update:modelValue", data);
    }

    const uploadImage = (e) => {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);

      let imageData = null;
      reader.onload = (e) => {
        imageData = e.target.result;
      };

      progress.value = 0;
      message = "";
      upload(image, (event) => {
        progress.value = Math.round((100 * event.loaded) / event.total);
      }, '')
        .then((response) => {
          message = response.data.message;
          imageData = `${process.env.VUE_APP_URL_PUBLIC+'/produto/'}`+response.data.data.filename;
          previewImage.value = imageData;
          sendEmit(response.data.data.filename);
          openToast(message, "success");
        })
        .catch((err) => {
          progress.value = 0;
          message = "Could not upload the image! " + err;
          previewImage.value = null;
          sendEmit("");
          openToast(message, "error");
        });
    };

    const removeImage = () => {
      progress.value = 0;
      message = "Imagem Removida!";
      previewImage.value = null;
      sendEmit("");
      openToast("Imagem Removida!", "info");
    }

    return {
      uploadImage,
      progress,
      previewImage,
      message,
      removeImage,

      publicUrl: `${process.env.VUE_APP_URL_PUBLIC+'/produto/'}`
    };
  },
};
</script>

<style scoped>
.preview {
  max-width: 200px;
}
</style>
