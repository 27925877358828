<template>
  <section>
    <!-- form-geral -->
    <form @submit.prevent="editProduto">
      <div class="card card_border">
        <div class="cards__heading">
          <h3>Dados Gerais</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">marca (montadora)</label>
              <y-select
                  v-model="produto.marca_id"
                  :selected="produto.marca_id"
                  :options="marcas"
                />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">veiculo</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="veiculo"
                name="veiculo" v-model="produto.veiculo"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">lado</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="lado"
                name="lado" v-model="produto.lado"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">ano de aplicação inicial</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="ano_inicial"
                name="ano_inicial" v-model="produto.ano_inicial"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">ano de aplicação final</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="ano_final"
                name="ano_final" v-model="produto.ano_final"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">referencia</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="referencia"
                name="referencia" v-model="produto.referencia"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">codigo_genuino</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="Codigo Genuíno"
                name="codigo_genuino" v-model="produto.codigo_genuino"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">preco</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="preco"
                name="preco" v-model="produto.preco"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">descricao</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="descricao"
                name="descricao" v-model="produto.descricao"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">obs</label>
              <textarea
                class="form-control input-style"
                id="inputEmail4"
                placeholder="obs"
                name="obs" v-model="produto.obs"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem_1</label>
              <image-upload
                v-model="produto.imagem_1"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem_2</label>
              <image-upload
                v-model="produto.imagem_2"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem_3</label>
              <image-upload
                v-model="produto.imagem_3"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem_4</label>
              <image-upload
                v-model="produto.imagem_4"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem_5</label>
              <image-upload
                v-model="produto.imagem_5"
              />
              
            </div>
          </div>
        </div>
        <div class="card-footer">

          <button type="submit" class="btn btn-primary btn-style">
            Salvar
          </button>
          
          <button class="btn btn-link"
            @click="()=>$router.push({ name: `${currentMeta.base}.search` })">
            Voltar
          </button>

        </div>
      </div>
    </form>
    <!-- /form-geral -->
  </section>
</template>

<script>

import { onMounted } from "vue";
import { useRouter } from "vue-router";

import useCommon from "@/common";
import useProduto from '@/composables/Produto';
// import useTipo from '@/composables/Tipo';
import useMarca from '@/composables/Marca';
// import useVeiculo from '@/composables/Veiculo';

export default {
  props: {
    id: {
      required: false,
          type: String
    }
  },

  setup(props) {

    const { openToast } = useCommon();
    const { status, message, error, produto, getProduto, updateProduto } = useProduto();

    // const { tipos, getTipos } = useTipo();
    const { marcas, getMarcas } = useMarca();
    // const { veiculos, getVeiculos } = useVeiculo();

    const router = useRouter();
    const currentMeta = router.currentRoute.value.meta;

    const showMessages = () => {
      if(status.value){
        openToast(message.value, 'success');
      }else{
        openToast(error.value, 'error');
      }
    };

    //edit
    const editProduto = async () => {
      await updateProduto(props.id);
      showMessages();
    }

    onMounted(function(){
      getProduto(props.id);

      // getTipos(-1);
      getMarcas(-1);
      // getVeiculos(-1);
    });

    return {
      produto,
      editProduto,
      currentMeta,

      // tipos,
      marcas,
      // veiculos,
    };
  },
};
</script>
